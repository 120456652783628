import React from 'react'
import Title from '../components/UI/Title'
import { getImageBySize } from '../utils'
import { Helmet } from 'react-helmet'
import { FaFacebook, FaInstagram, FaLinkedin } from 'react-icons/fa'
import Form from '../components/Form'

const Contact = ({ data }) => {

  return (
    <div className="h-screen overflow-y-auto overflow-x-hidden flex flex-col justify-center items-center">
      <Helmet htmlAttributes={{ lang: 'fr' }}>
        <meta charSet="utf-8" />
        <title>Wizito</title>
        <meta httpEquiv="Content-Language" content="fr" />
        <meta name="description" content={`Wizito`} />
        <meta name="robots" content="noindex,nofollow" />
        <link rel="icon" type="image/png" href="/favicon.ico" />
      </Helmet>
      <div className="p-4 text-center">
        <div className="text-3xl font-bold text-blue-dark mb-6">Contact</div>
        <Form locale="fr" defaultType="professional" inline />
      </div>
    </div>
  )
}
export default Contact
